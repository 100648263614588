import { routeNames, routePaths, Routes } from "../constants/routes";
import { Roles } from "../constants/user";
import { FieldRenderers, FormFieldTypes } from "../types/form";
import { NavItemProps } from "../components/nav-item/interface";
import { fieldIds, PORTAL } from "../constants/fields";

const trainingPortalPages: Array<NavItemProps> = [
  {
    title: routeNames[Routes.TRAINING_PORTAL_USER_TYPES],
    href: routePaths[Routes.TRAINING_PORTAL_USER_TYPES],
  },
  {
    title: routeNames[Routes.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS],
    href: routePaths[Routes.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS],
  },
];

const clientPortalPages: Array<NavItemProps> = [
  {
    title: routeNames[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS],
    href: routePaths[Routes.CLIENT_PORTAL_PAGE_RESTRICTIONS],
  },
  {
    title: routeNames[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS],
    href: routePaths[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS],
  },
];

export const generateNavConfig = (userRoles: Roles): Array<NavItemProps> => [
  {
    href: routePaths[Routes.PENDING_RECORDS],
    title: routeNames[Routes.PENDING_RECORDS],
    children: [
      {
        href: routePaths[Routes.PENDING_REGISTRATIONS],
        title: routeNames[Routes.PENDING_REGISTRATIONS],
      },
      {
        href: routePaths[Routes.PENDING_REQUESTS],
        title: routeNames[Routes.PENDING_REQUESTS],
      },
      {
        href: routePaths[Routes.PENDING_EMAIL_CHANGES],
        title: routeNames[Routes.PENDING_EMAIL_CHANGES],
      },
    ],
  },
  {
    href: routePaths[Routes.USER_MANAGEMENT],
    title: routeNames[Routes.USER_MANAGEMENT],
    children: [
      {
        title: routeNames[Routes.PORTAL_USERS],
        href: routePaths[Routes.PORTAL_USERS],
      },
    ],
  },
  {
    title: routeNames[Routes.EMAIL_LOG],
    href: routePaths[Routes.EMAIL_LOG],
  },
  {
    title: routeNames[Routes.SETTINGS],
    href: routePaths[Routes.SETTINGS],
    // here i am using a ternary to destructure either an array containing the items we want to add
    // or an empty array so that when no user roles match nothing is added to the array.
    children: [
      ...(userRoles === Roles.DualAdmin || userRoles === Roles.ClientPortalAdmin
        ? clientPortalPages
        : []),
      ...(userRoles === Roles.DualAdmin ||
      userRoles === Roles.TrainingPortalAdmin
        ? trainingPortalPages
        : []),
    ],
  },
  ...(userRoles === Roles.ClientPortalAdmin || userRoles === Roles.DualAdmin
    ? [
        {
          title: routeNames[Routes.REPORTS],
          href: routePaths[Routes.REPORTS],
        },
      ]
    : []),
];

export const getPortalSelectConfig = (
  userRoles: Roles
): Array<FormFieldTypes> => {
  if (userRoles === Roles.DualAdmin) {
    return [
      {
        id: fieldIds.PORTAL,
        renderer: FieldRenderers.SELECT,
        validators: [],
        options: [
          {
            value: "",
            displayText: "Select Portal",
          },
          {
            value: "1",
            displayText: "Client Portal",
          },
          {
            value: "2",
            displayText: "Training Portal",
          },
        ],
        label: "Select portal",
        isLabelHidden: true,
      },
    ];
  } else {
    return [];
  }
};
