// Hardcoded list of property keys that are expected from the server api calls.
export const dataKeys = {
  id: "id",
  contentId: "contentId",
  azureUserId: "azureUserId",
  restrictionId: "restrictionId",
  pageName: "pageName",
  viewedPageName: "viewedPageName",
  name: "name",
  salesforceCampaignId: "salesforceCampaignId",
  campaignName: "campaignName",
  trainingPortalUserTypeContentId: "trainingPortalUserTypeContentId",
  trainingPortalExUserTypeContentId: "trainingPortalExUserTypeContentId",
  firstName: "firstName",
  surname: "surname",
  userId: "userId",
  userFirstName: "userFirstName",
  userSurname: "userSurname",
  employeeFirstName: "employeeFirstName",
  employeeSurname: "employeeSurname",
  contactId: "contactId",
  email: "email",
  oldEmailAddress: "oldEmailAddress",
  emailAddress: "emailAddress",
  emailType: "emailType",
  dateTimeSent: "dateTimeSent",
  company: "company",
  insightInvestmentContact: "insightInvestmentContact",
  phoneNumber: "phoneNumber",
  displayName: "displayName",
  date: "date",
  time: "time",
  dateTime: "dateTime",
  portal: "portal",
  registrationRejectionReason: "registrationRejectionReason",
  cpdApplicable: "cpdApplicable",
  isExUserType: "isExUserType",
  status: "status",
  accountPortfolio: "accountPortfolio",
  client: "client",
  documentFolder: "documentFolder",
  fileDownloaded: "fileDownloaded",
  registrationApprovalEmailSubject: "registrationApprovalEmailSubject",
  registrationApprovalEmailBody: "registrationApprovalEmailBody",
  requestApprovalEmailSubject: "requestApprovalEmailSubject",
  requestApprovalEmailBody: "requestApprovalEmailBody",
  subject: "subject",
  emailBody: "emailBody",
  portalType: "portalType",
  adminEmailAddress: "adminEmailAddress",
  lastSubmissionDate: "lastSubmissionDate",
  trainingPortalUserType: "trainingPortalUserType",
  trainingPortalExUserType: "trainingPortalExUserType",
  auditLogDateTime: "auditLogDateTime",
  userSalesforceContactId: "userSalesforceContactId",
  activity: "activity",
  selectedClient: "selectedClient",
  selectedAccountPortfolioName: "selectedAccountPortfolioName",
  documentsPageFolder: "documentsPageFolder",
  documentName: "documentName",
  requestedEmailChangeDateTime: "requestedEmailChangeDateTime",
  authorEmailAddress: "authorEmailAddress",
  note: "note",
  authorUserId: "authorUserId",
  authorFirstName: "authorFirstName",
  authorSurname: "authorSurname",
  hasReadWriteOption: "hasReadWriteOption",
  sharePointAgreementFolder: "sharePointAgreementFolder",
  instructionReference: "instructionReference",
  portalHubPageCmsId: "portalHubPageCmsId",
};

export const tableHeaders: Record<string, string> = {
  [dataKeys.id]: "ID",
  [dataKeys.restrictionId]: "Restriction ID",
  [dataKeys.pageName]: "Page Name",
  [dataKeys.name]: "Name",
  [dataKeys.salesforceCampaignId]: "Salesforce Campaign ID",
  [dataKeys.campaignName]: "Campaign Name",
  [dataKeys.trainingPortalUserTypeContentId]: "Training Portal User Type",
  [dataKeys.trainingPortalExUserTypeContentId]: "Training Portal Ex User Type",
  [dataKeys.firstName]: "First Name",
  [dataKeys.surname]: "Surname",
  [dataKeys.email]: "Email",
  [dataKeys.emailAddress]: "To",
  [dataKeys.adminEmailAddress]: "Admin email address",
  [dataKeys.emailType]: "Email Type",
  [dataKeys.oldEmailAddress]: "Old email address",
  [dataKeys.dateTimeSent]: "Date Sent",
  [dataKeys.company]: "Company",
  [dataKeys.insightInvestmentContact]: "Insight Investment Contact",
  [dataKeys.phoneNumber]: "Phone Number",
  [dataKeys.displayName]: "Display Name",
  [dataKeys.date]: "Date",
  [dataKeys.dateTime]: "Date & Time",
  [dataKeys.auditLogDateTime]: "Date",
  [dataKeys.requestedEmailChangeDateTime]: "Date of change request",
  [dataKeys.time]: "Time",
  [dataKeys.portal]: "Portal",
  [dataKeys.status]: "Status",
  [dataKeys.registrationRejectionReason]: "Registration Rejection Reason",
  [dataKeys.cpdApplicable]: "CPD Applicable",
  [dataKeys.isExUserType]: "Ex-User Type",
  [dataKeys.registrationApprovalEmailSubject]:
    "Registration approval email subject",
  [dataKeys.registrationApprovalEmailBody]: "Registration approval email body",
  [dataKeys.requestApprovalEmailSubject]:
    "Access request approval email subject",
  [dataKeys.requestApprovalEmailBody]: "Access request approval email body",
  [dataKeys.lastSubmissionDate]: "Last Submission Date",
  [dataKeys.trainingPortalUserType]: "Training portal user type",
  [dataKeys.trainingPortalExUserType]: "Training portal ex-user type",
  [dataKeys.employeeFirstName]: "Employee first name",
  [dataKeys.employeeSurname]: "Employee surname",
  [dataKeys.authorEmailAddress]: "Author Email",
  [dataKeys.note]: "Email Change Rejection Reason",
  [dataKeys.authorFirstName]: "Author First Name",
  [dataKeys.authorSurname]: "Author Surname",
  [dataKeys.sharePointAgreementFolder]: "Agreement",
  [dataKeys.instructionReference]: "Instruction reference",
  [dataKeys.portalHubPageCmsId]: "Portal Hub Page CMS ID",
};

export const trainingPortalUserTypesTableHeaders: Record<string, string> =
  Object.assign({}, tableHeaders, {
    name: "Training portal user type",
  });

export const pendingEmailChangesTableHeaders: Record<string, string> =
  Object.assign({}, tableHeaders, {
    email: "New email address",
    portal: "Portal Access",
  });

export const pendingRegistrationsTableHeaders: Record<string, string> =
  Object.assign({}, tableHeaders, {
    email: "Email Address",
    portal: "Portal",
    lastSubmissionDate: "Date",
  });

export const pendingRequestsTableHeaders: Record<string, string> =
  Object.assign({}, tableHeaders, {
    email: "Email Address",
    portal: "Portal",
    lastSubmissionDate: "Date",
  });

export const portalUsersTableHeaders: Record<string, string> = Object.assign(
  {},
  tableHeaders,
  {
    email: "Email Address",
    dateTime: "Approval / Rejection Date & Time",
  }
);

export const reportingTableHeaders: Record<string, string> = Object.assign(
  {},
  tableHeaders,
  {
    viewedPageName: "Page",
    userFirstName: "First name",
    userSurname: "Surname",
    userSalesforceContactId: "Contact ID",
    activity: "Activity",
    selectedClient: "Client",
    selectedAccountPortfolioName: "Account / portfolio",
    documentsPageFolder: "Document folder",
    documentName: "Document",
    time: "Time",
    date: "Date",
  }
);
