import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { routeApis } from "../../../constants/apis";
import { columnConfig } from "../../../constants/columnConfig";
import { Routes } from "../../../constants/routes";
import { dataKeys, tableHeaders } from "../../../constants/tableHeaders";
import { orderColumns } from "../../../helpers/utils";
import { FieldRenderers, FormConfig } from "../../../types/form";
import { FieldValidatorTypes } from "../../../validation/validator";
import { SettingsPageTemplate } from "../../templates/settingsPageTemplate";

export const ClientPortalSalesforceCampaigns = (): JSX.Element => {
  let { data, error }: any = useLoaderData();

  const {
    hiddenColumns: hidden,
    order,
    sortable,
  } = columnConfig.clientPortalSalesforceCampaigns;
  data = orderColumns(data, order);

  const [shouldCloseDialogOnEsc, setShouldCloseDialogOnEsc] =
    useState<boolean>(true);

  const ConfigMap: Record<string, any> = {
    name: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.name} is required`,
        },
      ],
    },
    campaignName: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.campaignName} is required`,
        },
      ],
    },
    salesforceCampaignId: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.salesforceCampaignId} is required`,
        },
      ],
    },
    trainingPortalUserTypeContentId: {
      renderer: FieldRenderers.INPUT,
      type: "hidden",
      isLabelHidden: true,
    },
    trainingPortalExUserTypeContentId: {
      renderer: FieldRenderers.INPUT,
      type: "hidden",
      isLabelHidden: true,
    },
    portal: {
      renderer: FieldRenderers.INPUT,
      type: "hidden",
      isLabelHidden: true,
      initialValue: "ClientPortal",
    },
    adminEmailAddress: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.adminEmailAddress} is required`,
        },
      ],
    },
    registrationApprovalEmailSubject: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.registrationApprovalEmailSubject} is required`,
        },
      ],
    },
    registrationApprovalEmailBody: {
      renderer: FieldRenderers.TEXT_AREA,
      type: "text",
      rteFocusCallback: (value: boolean) => setShouldCloseDialogOnEsc(!value),
      shouldRenderAsRte: true,
      // rteToolBarOptions: 'bold italic | bullist numlist | link unlink | code',
      hint: "<div class='u-m-2'>The following variables are available: <ol class='u-m-0'> <li>First name - [firstname] </li> <li> Surname - [surname]</li> <li>Display name - [displayname]</li></ol></div>",
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.registrationApprovalEmailBody} is required`,
        },
      ],
    },
    requestApprovalEmailSubject: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.requestApprovalEmailSubject} is required`,
        },
      ],
    },
    requestApprovalEmailBody: {
      renderer: FieldRenderers.TEXT_AREA,
      type: "text",
      rteFocusCallback: (value: boolean) => setShouldCloseDialogOnEsc(!value),
      shouldRenderAsRte: true,
      // rteToolBarOptions: 'bold italic | bullist numlist | link unlink | code',
      hint: "<div class='u-m-2'>The following variables are available: <ol class='u-m-0'> <li>First name - [firstname] </li> <li> Surname - [surname]</li> <li>Display name - [displayname]</li></ol></div>",
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.requestApprovalEmailBody} is required`,
        },
      ],
    },
    portalHubPageCmsId: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `Please provide the CMS ID of the appropriate portal hub page`,
        },
      ],
    },
  };

  const generateFormConfig = (item: Record<string, string>): FormConfig => {
    return {
      fields: Object.keys(item).map((key: string) => ({
        id: key,
        renderer: FieldRenderers.INPUT,
        validators: [],
        label: tableHeaders[key] ?? key,
        isLabelHidden: false,
        initialValue: item[key],
        ...ConfigMap[key],
        classes: {
          label: "o-form-label u-font-bold u-text-lg",
        },
      })),
    };
  };

  const newItemFormData: Record<string, string | null> = {
    campaignName: "",
    portal: "ClientPortal",
    salesforceCampaignId: "",
    adminEmailAddress: "",
    trainingPortalExUserTypeContentId: null,
    trainingPortalUserTypeContentId: null,
    registrationApprovalEmailSubject: "",
    registrationApprovalEmailBody: "",
    requestApprovalEmailSubject: "",
    requestApprovalEmailBody: "",
    portalHubPageCmsId: "",
  };

  return (
    <>
      <SettingsPageTemplate
        tableData={data}
        sortableColumns={sortable}
        hiddenColumns={hidden}
        columnOrder={order}
        pageTitleText="Client Portal Salesforce Campaigns"
        modalText="Client Salesforce Campaigns"
        noResultsText="There are no salesforce campaigns to show. Please try again"
        addNewItemText="Add new campaign"
        saveText="Save campaign"
        cancelText="Cancel"
        tableCaptionText="Client Portal salesforce campaigns"
        viewText="Edit"
        dataItemKey={dataKeys.campaignName}
        generateFormConfig={generateFormConfig}
        newItemFormData={newItemFormData}
        shouldCloseDialogOnEsc={shouldCloseDialogOnEsc}
        apis={{
          get: routeApis[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]
            .getSalesforceCampaigns,
          update:
            routeApis[Routes.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS]
              .editSalesforceCampaigns,
        }}
        error={error}
      />
    </>
  );
};
