export const enum Roles {
  DualAdmin = "ClientPortal.TrainingPortal.Administrator",
  TrainingPortalAdmin = "TrainingPortal.Administrator",
  ClientPortalAdmin = "ClientPortal.Administrator",
  None = "None",
}

export const enum Portal {
  TrainingPortal = "TrainingPortal",
  ClientPortal = "ClientPortal",
}

export const enum ClientPortalPageRestrictionPermissions {
  NoAccess = "NoAccess",
  ReadOnly = "ReadOnly",
  ReadWrite = "ReadWrite",
}
