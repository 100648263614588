import { FormConfig } from "../../types/form";
import { getPortalSelectConfig } from "../../helpers/config";
import { Roles } from "../../constants/user";
import { queryBannerFields } from "../fields";
export const pendingPagesForm = (userRoles: Roles): FormConfig => ({
  fields: [
    queryBannerFields.emailAddress,
    ...getPortalSelectConfig(userRoles),
    queryBannerFields.fromDate,
    queryBannerFields.toDate,
  ],
});
