import { routeApis } from "../../constants/apis";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const trainingPortalSalesforceCampaignsLoader = async (props?: any) => {
  const [{ data }, { data: userTypes }] = await Promise.all([
    getPageData({
      url: routeApis[Routes.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS]
        .getSalesforceCampaigns,
      ...props,
    }),
    getPageData({
      url: routeApis[Routes.TRAINING_PORTAL_USER_TYPES].getPortalUserType,
    }),
  ]);

  const newTableData = data?.map((item: any) => {
    const newItem = { ...item };
    userTypes?.forEach((type: any) => {
      if (type?.contentId === item.trainingPortalUserTypeContentId) {
        newItem.trainingPortalUserTypeContentId = type.name;
      }
      if (type?.contentId === item.trainingPortalExUserTypeContentId) {
        newItem.trainingPortalExUserTypeContentId = type.name;
      }
    });
    return newItem;
  });

  return {
    data: newTableData,
    userTypes: userTypes ?? [],
  };
};
