import { createBrowserRouter, Navigate, Routes } from "react-router-dom";
import { routePaths } from "../constants/routes";
import { LayoutContainer } from "../containers/layout/layout";
import { clientPortalPageRestrictionsLoader } from "../loaders/pages/clientPortalPageRestrictions";
import ErrorPage from "./error/error";
import { Routes as appRoutesEnum } from "../constants/routes";
import { ClientPortalPageRestrictions } from "./pages/clientPortalPageRestrictions";
import { PendingRequests } from "./pages/pendingRequests";
import { pendingRequestsLoader } from "../loaders/pages/pendingRequests";
import { PendingRegistrations } from "./pages/pendingRegistrations";
import { TrainingPortalUserTypes } from "./pages/trainingPortalUserTypes";
import { trainingPortalUserTypesLoader } from "../loaders/pages/trainingPortalUserTypes";
import { TrainingPortalSalesforceCampaigns } from "./pages/trainingPortalSalesforceCampaigns";
import { trainingPortalSalesforceCampaignsLoader } from "../loaders/pages/trainingPortalSalesforceCampaigns";
import { clientPortalSalesforceCampaignsLoader } from "../loaders/pages/clientPortalSalesforceCampaigns";
import { ClientPortalSalesforceCampaigns } from "./pages/clientPortalSalesforceCampaigns";
import { pendingRegistrationsLoader } from "../loaders/pages/pendingRegistrations";
import { PendingEmailChanges } from "./pages/pendingEmailChanges";
import { pendingEmailChangesLoader } from "../loaders/pages/pendingEmailChanges";
import { PortalUsers } from "./pages/portalUsers";
import { portalUsersLoader } from "../loaders/pages/portalUsers";
import { EmailLogPage } from "./pages/emailLog";
import { emailLogLoader } from "../loaders/pages/emailLog";
import { reportingLoader } from "../loaders/pages/reporting";
import { Reporting } from "./pages/reporting";
import { Roles } from "../constants/user";

const clientPortalPages: Array<any> = [
  {
    path: routePaths[appRoutesEnum.CLIENT_PORTAL_PAGE_RESTRICTIONS],
    element: <ClientPortalPageRestrictions />,
    loader: clientPortalPageRestrictionsLoader,
  },
  {
    path: routePaths[appRoutesEnum.CLIENT_PORTAL_SALESFORCE_CAMPAIGNS],
    element: <ClientPortalSalesforceCampaigns />,
    loader: clientPortalSalesforceCampaignsLoader,
  },
];

const trainingPortalPages: Array<any> = [
  {
    path: routePaths[appRoutesEnum.TRAINING_PORTAL_USER_TYPES],
    element: <TrainingPortalUserTypes />,
    loader: trainingPortalUserTypesLoader,
  },
  {
    path: routePaths[appRoutesEnum.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS],
    element: <TrainingPortalSalesforceCampaigns />,
    loader: trainingPortalSalesforceCampaignsLoader,
  },
];

export const appRouter = (userRoles: Roles) =>
  createBrowserRouter([
    {
      path: routePaths[appRoutesEnum.HOME],
      element: <LayoutContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: (
            <Navigate to={routePaths[appRoutesEnum.PENDING_REGISTRATIONS]} />
          ),
        },
        {
          path: routePaths[appRoutesEnum.PENDING_RECORDS],
          element: <Navigate to={routePaths[appRoutesEnum.PENDING_REQUESTS]} />,
        },
        {
          path: routePaths[appRoutesEnum.PENDING_REGISTRATIONS],
          element: <PendingRegistrations />,
          loader: pendingRegistrationsLoader,
        },
        {
          path: routePaths[appRoutesEnum.PENDING_REQUESTS],
          element: <PendingRequests />,
          loader: pendingRequestsLoader,
        },
        {
          path: routePaths[appRoutesEnum.PENDING_EMAIL_CHANGES],
          element: <PendingEmailChanges />,
          loader: pendingEmailChangesLoader,
        },
        {
          path: routePaths[appRoutesEnum.EMAIL_LOG],
          element: <EmailLogPage />,
          loader: emailLogLoader,
        },
        {
          path: routePaths[appRoutesEnum.SETTINGS],
          element: (
            <Navigate
              to={
                routePaths[
                  userRoles === Roles.DualAdmin ||
                  userRoles === Roles.ClientPortalAdmin
                    ? appRoutesEnum.CLIENT_PORTAL_PAGE_RESTRICTIONS
                    : appRoutesEnum.TRAINING_PORTAL_USER_TYPES
                ]
              }
            />
          ),
        },
        ...(userRoles === Roles.DualAdmin ||
        userRoles === Roles.ClientPortalAdmin
          ? clientPortalPages
          : []),
        ...(userRoles === Roles.DualAdmin ||
        userRoles === Roles.TrainingPortalAdmin
          ? trainingPortalPages
          : []),
        {
          path: routePaths[appRoutesEnum.USER_MANAGEMENT],
          element: <Navigate to={routePaths[appRoutesEnum.PORTAL_USERS]} />,
        },
        {
          path: routePaths[appRoutesEnum.PORTAL_USERS],
          element: <PortalUsers />,
          loader: portalUsersLoader,
        },

        ...(userRoles === Roles.ClientPortalAdmin ||
        userRoles === Roles.DualAdmin
          ? [
              {
                path: routePaths[appRoutesEnum.REPORTS],
                element: <Reporting />,
                loader: reportingLoader,
              },
            ]
          : []),
      ],
    },
  ]);
