import { useLoaderData } from "react-router-dom";
import { SettingsPageTemplate } from "../../templates/settingsPageTemplate";

import { routeApis } from "../../../constants/apis";
import { Routes } from "../../../constants/routes";
import { FieldRenderers, FormConfig } from "../../../types/form";
import { FieldValidatorTypes } from "../../../validation/validator";
import {
  dataKeys,
  trainingPortalUserTypesTableHeaders as tableHeaders,
  trainingPortalUserTypesTableHeaders,
} from "../../../constants/tableHeaders";
import { columnConfig } from "../../../constants/columnConfig";
import { orderColumns } from "../../../helpers/utils";

export const TrainingPortalUserTypes = (): JSX.Element => {
  let { data, error }: any = useLoaderData();

  const {
    hiddenColumns: hidden,
    order,
    sortable,
  } = columnConfig.trainingPortalUserTypes;
  data = orderColumns(data, order);

  const ConfigMap: Record<string, any> = {
    name: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.name} is required`,
        },
      ],
    },
    isExUserType: {
      renderer: FieldRenderers.CHECKBOX,
      type: "checkbox",
    },
    cpdApplicable: {
      renderer: FieldRenderers.CHECKBOX,
      type: "hidden",
      isLabelHidden: true,
    },
  };

  const generateFormConfig = (item: Record<string, string>): FormConfig => {
    return {
      fields: Object.keys(item).map((key: string) => ({
        id: key,
        renderer: FieldRenderers.INPUT,
        validators: [],
        label: trainingPortalUserTypesTableHeaders[key] ?? key,
        isLabelHidden: false,
        initialValue: item[key],
        classes: {
          label: "o-form-label u-font-bold u-text-lg",
        },
        ...ConfigMap[key],
      })),
    };
  };

  const newItemFormData: Record<string, any> = {
    name: "",
    cpdApplicable: true,
    isExUserType: false,
  };

  return (
    <SettingsPageTemplate
      tableData={data}
      sortableColumns={sortable}
      hiddenColumns={hidden}
      columnOrder={order}
      pageTitleText="Training Portal User Types"
      modalText="Training Portal User Type"
      noResultsText="There are no training user types to show. Please try again"
      addNewItemText="Add new user type"
      saveText="Save user type"
      cancelText="Cancel"
      tableCaptionText="Training portal user types"
      viewText="Edit"
      dataItemKey={dataKeys.name}
      generateFormConfig={generateFormConfig}
      newItemFormData={newItemFormData}
      apis={{
        get: routeApis[Routes.TRAINING_PORTAL_USER_TYPES].getPortalUserType,
        update: routeApis[Routes.TRAINING_PORTAL_USER_TYPES].editPortalUserType,
      }}
      columnHeaderTextMap={tableHeaders}
      error={error}
    />
  );
};
