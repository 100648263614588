import { routeApis } from "../../constants/apis";
import { Routes } from "../../constants/routes";
import { getPageData } from "../templates/page";

export const trainingPortalUserTypesLoader = async (props: any) => {
  return getPageData({
    url: routeApis[Routes.TRAINING_PORTAL_USER_TYPES].getPortalUserType,
    ...props,
  });
};
