import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./routes/router";
import { useEffect, useState } from "react";
import { fetchWithTimeOut, setFetchOpts } from "./helpers/fetch";
import { RequestMethods } from "./constants/fetch";
import { authApis } from "./constants/apis";
import { Roles } from "./constants/user";
import { AppContext, rootElementId } from "./constants/context";
import { Loading } from "./components/loading";
import { Error } from "./components/error";
import {
  IS_AUTHENTICATED_API_FAILED,
  USER_ROLE_API_FAILED,
} from "./constants/messages";

const AuthProvider = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<Roles>(Roles.None);
  const [error, setError] = useState<string | undefined>(undefined);
  const appInitialisationReady: boolean =
    isAuthenticated && Boolean(userRoles?.length);

  const requestAuth = async () => {
    const fetchOptions = setFetchOpts({
      method: RequestMethods.GET,
    });

    const authenticationResponse = await fetchWithTimeOut(
      authApis.isAuthenticated,
      fetchOptions,
      10000
    );

    if (authenticationResponse.ok) {
      const isUserLoggedIn = await authenticationResponse.json();

      if (isUserLoggedIn) {
        const fetchOptions = setFetchOpts({
          method: RequestMethods.GET,
        });

        const userRoleResponse = await fetchWithTimeOut(
          authApis.userRole,
          fetchOptions,
          10000
        );

        if (userRoleResponse.ok) {
          const userRole = await userRoleResponse.json();

          setUserRoles(userRole);
          setIsAuthenticated(true);
        } else {
          setError(USER_ROLE_API_FAILED);
        }
      } else {
        window.location.href = authApis.authLogin;
      }
    } else {
      setError(IS_AUTHENTICATED_API_FAILED);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) requestAuth();
  }, []);

  if (error) {
    return <Error message={error} />;
  }

  if (appInitialisationReady) {
    return (
      <AppContext.Provider value={{ userRoles: userRoles }}>
        <RouterProvider router={appRouter(userRoles)} />
      </AppContext.Provider>
    );
  }

  return (
    <div>
      <Loading className="u-fixed u-w-full u-h-full" isActive={true} />
    </div>
  );
};

const init = async () => {
  const root = ReactDOM.createRoot(
    document.getElementById(rootElementId) as HTMLElement
  );

  root.render(<AuthProvider />);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
