import { Checkbox } from "../components/forms/checkbox";
import { DatePicker } from "../components/forms/datepicker";
import { Input } from "../components/forms/input";
import { Number } from "../components/forms/number";
import { Search } from "../components/forms/search";
import { Select } from "../components/forms/select";
import { TextArea } from "../components/TextArea";

export const enum mediaQueries {
  MOBILE = "(max-width: 320px)",
  MOBILE_LARGE = "(max-width: 400px)",
  TABLET = "(min-width: 768px)",
  MAX_TABLET = "(max-width: 1024px)",
  DESKTOP = "(min-width: 1024px)",
}
export const DATE_PICKER: string = "DATE_PICKER";
export const SELECT: string = "SELECT";
export const SEARCH: string = "SEARCH";
export const INPUT: string = "INPUT";
export const CHECKBOX: string = "CHECKBOX";
export const TEXT_AREA: string = "TEXT_AREA";
export const NUMBER: string = "NUMBER";

export const renderers: Record<string, (props: any) => JSX.Element> = {
  [SELECT]: Select,
  [SEARCH]: Search,
  [DATE_PICKER]: DatePicker,
  [INPUT]: Input,
  [CHECKBOX]: Checkbox,
  [TEXT_AREA]: TextArea,
  [NUMBER]: Number,
};

export const enum actions {
  VIEW = "View",
  EDIT = "Edit",
}

export const DEFAULT_DATE_INPUT_FORMAT: string = "yyyy-MM-dd";

export const messageConstants: Record<string, string> = {
  validatorMisconfigured: "validator has been supplied incorrect config",
};

export enum portalTypes {
  ClientPortal = "Client Portal",
  TrainingPortal = "Training Portal",
  ClientPortalTrainingPortal = "Client Portal & Training Portal",
}

export enum portalUserStatuses {
  NoPortalAssigned = "No Portal Assigned",
  PendingRegistration = "Pending Registration",
  PendingEmailChange = "Pending Email Change",
  PendingAccessRequest = "Pending Access Request",
  Active = "Active",
  Rejected = "Rejected",
  Removed = "Removed",
  Resubmitted = "Resubmitted",
  ResubmittedActive = "[Resubmitted] Active",
  ResubmittedRemoved = "[Resubmitted] Removed",
  ResubmittedRejected = "[Resubmitted] Rejected",
}
