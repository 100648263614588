import { useLoaderData } from "react-router-dom";
import { SettingsPageTemplate } from "../../templates/settingsPageTemplate";

import { routeApis } from "../../../constants/apis";
import { Routes } from "../../../constants/routes";
import { FieldRenderers, FormConfig } from "../../../types/form";
import { FieldValidatorTypes } from "../../../validation/validator";
import { dataKeys, tableHeaders } from "../../../constants/tableHeaders";
import { useState } from "react";
import { columnConfig } from "../../../constants/columnConfig";
import { orderColumns } from "../../../helpers/utils";
import { trainingPortalSalesforceCampaignsLoader } from "../../../loaders/pages/trainingPortalSalesforceCampaigns";

interface UserTypes {
  contentId: number;
  cpdApplicable: boolean;
  isExUserType: boolean;
  name: string;
}

export const TrainingPortalSalesforceCampaigns = (): JSX.Element => {
  let { data, userTypes, error }: any = useLoaderData();
  const [shouldCloseDialogOnEsc, setShouldCloseDialogOnEsc] =
    useState<boolean>(true);

  const nonExUserTypes: Array<UserTypes> = userTypes?.filter(
    (type: any) => !type.isExUserType
  );
  const exUserTypes: Array<UserTypes> = userTypes?.filter(
    (type: any) => type.isExUserType
  );

  const { hiddenColumns, order, sortable } =
    columnConfig.trainingPortalSalesforceCampaigns;
  data = orderColumns(data, order);

  const ConfigMap: Record<string, any> = {
    salesforceCampaignId: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.salesforceCampaignId} is required`,
        },
      ],
    },
    campaignName: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.campaignName} is required`,
        },
      ],
    },
    trainingPortalUserTypeContentId: {
      renderer: FieldRenderers.SELECT,
      options: [
        {
          value: null,
          displayText: "Select Portal User Type",
        },
        ...nonExUserTypes?.map((item: any) => ({
          value: item?.contentId,
          displayText: item?.name,
        })),
      ],
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.trainingPortalUserTypeContentId} is required`,
        },
      ],
    },
    trainingPortalExUserTypeContentId: {
      renderer: FieldRenderers.SELECT,
      options: [
        {
          value: null,
          displayText: "Select Portal Ex-User Type",
        },
        ...exUserTypes?.map((item: any) => ({
          value: item?.contentId,
          displayText: item?.name,
        })),
      ],
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.trainingPortalExUserTypeContentId} is required`,
        },
      ],
    },
    portal: {
      renderer: FieldRenderers.INPUT,
      type: "hidden",
      isLabelHidden: true,
      initialValue: "TrainingPortal",
    },
    adminEmailAddress: {
      renderer: FieldRenderers.INPUT,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.adminEmailAddress} is required`,
        },
      ],
    },
    registrationApprovalEmailSubject: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.registrationApprovalEmailSubject} is required`,
        },
      ],
    },
    registrationApprovalEmailBody: {
      renderer: FieldRenderers.TEXT_AREA,
      type: "text",
      rteFocusCallback: (value: boolean) => setShouldCloseDialogOnEsc(!value),
      shouldRenderAsRte: true,
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.registrationApprovalEmailBody} is required`,
        },
      ],
      // rteToolBarOptions: 'bold italic | bullist numlist | link unlink | code',
      hint: "<div class='u-m-2'>The following variables are available: <ul class='u-m-0'> <li>First name - [firstname] </li> <li> Surname - [surname]</li> <li>Display name - [displayname]</li></ul></div>",
    },
    requestApprovalEmailSubject: {
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.requestApprovalEmailSubject} is required`,
        },
      ],
    },
    requestApprovalEmailBody: {
      renderer: FieldRenderers.TEXT_AREA,
      type: "text",
      rteFocusCallback: (value: boolean) => setShouldCloseDialogOnEsc(!value),
      shouldRenderAsRte: true,
      // rteToolBarOptions: 'bold italic | bullist numlist | link unlink | code',
      hint: "<div class='u-m-2'>The following variables are available: <ul class='u-m-0'> <li>First name - [firstname] </li> <li> Surname - [surname]</li> <li>Display name - [displayname]</li></ul></div>",
      validators: [
        {
          type: FieldValidatorTypes.Required,
          message: `${tableHeaders.requestApprovalEmailBody} is required`,
        },
      ],
    },
  };

  const generateFormConfig = (item: Record<string, any>): FormConfig => {
    return {
      fields: Object.keys(item).map((key: any) => {
        let userTypeContentId: number | undefined = undefined;

        // this is definitely not the best way to do this but there is not enough time to refactor in a clean generic way.
        userTypes.forEach((userType: UserTypes) => {
          if (
            (key === dataKeys.trainingPortalUserTypeContentId ||
              key === dataKeys.trainingPortalExUserTypeContentId) &&
            item[key] === userType.name
          ) {
            userTypeContentId = userType.contentId;
          }
        });

        const field = {
          id: key,
          renderer: FieldRenderers.INPUT,
          validators: [],
          label: tableHeaders[key] ?? key,
          isLabelHidden: false,
          classes: {
            label: "o-form-label u-font-bold u-text-lg",
          },
          ...ConfigMap[key],
          initialValue: userTypeContentId ?? item[key],
        };

        return field;
      }),
    };
  };

  const newItemFormData: Record<string, string | null> = {
    campaignName: "",
    portal: "TrainingPortal",
    salesforceCampaignId: "",
    adminEmailAddress: "",
    trainingPortalUserTypeContentId: null,
    trainingPortalExUserTypeContentId: null,
    registrationApprovalEmailSubject: "",
    registrationApprovalEmailBody: "",
    requestApprovalEmailSubject: "",
    requestApprovalEmailBody: "",
  };

  return (
    <SettingsPageTemplate
      tableData={data}
      sortableColumns={sortable}
      hiddenColumns={hiddenColumns}
      columnOrder={order}
      pageTitleText="Training Portal Salesforce Campaigns"
      modalText="Training Salesforce Campaigns"
      noResultsText="There are no salesforce campaigns to show. Please try again"
      addNewItemText="Add new campaign"
      saveText="Save campaign"
      cancelText="Cancel"
      tableCaptionText="Training portal salesforce campaigns"
      viewText="Edit"
      dataItemKey={dataKeys.campaignName}
      generateFormConfig={generateFormConfig}
      newItemFormData={newItemFormData}
      shouldCloseDialogOnEsc={shouldCloseDialogOnEsc}
      apis={{
        get: routeApis[Routes.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS]
          .getSalesforceCampaigns,
        update:
          routeApis[Routes.TRAINING_PORTAL_SALESFORCE_CAMPAIGNS]
            .editSalesforceCampaigns,
      }}
      getPageData={trainingPortalSalesforceCampaignsLoader}
      error={error}
    />
  );
};
