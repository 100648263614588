import { portalTypes, portalUserStatuses } from "../constants";
import { dataKeys } from "../constants/tableHeaders";

const dateFormatter = (value: any): any => {
  try {
    const date = new Date(value);
    // pass undefined as first argument to default to users locale
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } catch (error) {
    console.warn(
      "Date formatter has been supplied an unsuitable value to convert to date string"
    );
    console.warn(error);
    return value;
  }
};

export const tableCellFormatters: Record<string, (value: any) => any> = {
  [dataKeys.date]: dateFormatter,
  [dataKeys.dateTimeSent]: dateFormatter,
  [dataKeys.auditLogDateTime]: dateFormatter,
  [dataKeys.lastSubmissionDate]: dateFormatter,
  [dataKeys.portal]: (portalType: keyof typeof portalTypes): any =>
    portalTypes[portalType] || portalType,
  [dataKeys.status]: (status: keyof typeof portalUserStatuses): any =>
    portalUserStatuses[status] || status,
};
